.spline-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    background-color: #000;
  }
  
  .spline-container iframe {
    width: 100%;
    height: 100%;
    border: none;
  }