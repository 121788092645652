/* Hero Section */

:root {
    --primary-blue: #00a8ff;
    --secondary-blue: #0066cc;
    --accent-pink: #ff00ff;
    --deep-blue: #000033;
    --glow-blue: rgba(0, 168, 255, 0.2);
    --glow-pink: rgba(255, 0, 255, 0.2);
  }
  
.hero-section {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 4rem 0;
    display: flex;
    align-items: center;
    z-index: 10;
  }
  
  .hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(
      45deg,
      rgba(0, 168, 255, 0.1),
      rgba(255, 0, 255, 0.1)
    ); */
    z-index: 10;
  }
  
  .hero-content {
    position: relative;
    max-width: 800px;
    margin-left: 8%;
    padding: 0 2rem;
    animation: fadeSlideIn 1s ease forwards;
    text-align: left;
  }
  
  .hero-title {
    font-size: 4.5rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    line-height: 1.1;
    position: relative;
    letter-spacing: -1px;
    text-align: left;
  }
  
  .hero-title span {
    opacity: 0;
    display: inline-block;
    background: linear-gradient(120deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translate(-300px, 0) scale(0);
  }
  
  .hero-title.show span {
    animation: heroAni 0.5s forwards;
    transform: translate(-300px, 0) scale(0);
  }
  
  /* Animation delays pentru fiecare literă */
  .hero-title.show span:nth-of-type(1) {
    animation-delay: 0s;
  }
  .hero-title.show span:nth-of-type(2) {
    animation-delay: 0.05s;
  }
  .hero-title.show span:nth-of-type(3) {
    animation-delay: 0.1s;
  }
  .hero-title.show span:nth-of-type(4) {
    animation-delay: 0.15s;
  }
  .hero-title.show span:nth-of-type(5) {
    animation-delay: 0.2s;
  }
  .hero-title.show span:nth-of-type(6) {
    animation-delay: 0.25s;
  }
  .hero-title.show span:nth-of-type(7) {
    animation-delay: 0.3s;
  }
  .hero-title.show span:nth-of-type(8) {
    animation-delay: 0.35s;
  }
  .hero-title.show span:nth-of-type(9) {
    animation-delay: 0.4s;
  }
  .hero-title.show span:nth-of-type(10) {
    animation-delay: 0.45s;
  }
  .hero-title.show span:nth-of-type(11) {
    animation-delay: 0.5s;
  }
  .hero-title.show span:nth-of-type(12) {
    animation-delay: 0.55s;
  }
  .hero-title.show span:nth-of-type(13) {
    animation-delay: 0.6s;
  }
  .hero-title.show span:nth-of-type(14) {
    animation-delay: 0.65s;
  }
  .hero-title.show span:nth-of-type(15) {
    animation-delay: 0.7s;
  }
  .hero-title.show span:nth-of-type(16) {
    animation-delay: 0.75s;
  }
  .hero-title.show span:nth-of-type(17) {
    animation-delay: 0.8s;
  }
  .hero-title.show span:nth-of-type(18) {
    animation-delay: 0.85s;
  }
  .hero-title.show span:nth-of-type(19) {
    animation-delay: 0.9s;
  }
  .hero-title.show span:nth-of-type(20) {
    animation-delay: 0.95s;
  }
  .hero-title.show span:nth-of-type(21) {
    animation-delay: 1s;
  }
  
  @keyframes heroAni {
    60% {
      transform: translate(20px, 0) scale(1);
      background: linear-gradient(
        120deg,
        var(--accent-pink),
        var(--primary-blue)
      );
      -webkit-background-clip: text;
      background-clip: text;
    }
    80% {
      transform: translate(20px, 0) scale(1);
      background: linear-gradient(
        120deg,
        var(--accent-pink),
        var(--primary-blue)
      );
      -webkit-background-clip: text;
      background-clip: text;
    }
    99% {
      transform: translate(0) scale(1.2);
      background: linear-gradient(120deg, var(--primary-blue), #00f0ff);
      -webkit-background-clip: text;
      background-clip: text;
    }
    100% {
      transform: translate(0) scale(1);
      background: linear-gradient(
        120deg,
        var(--primary-blue),
        var(--accent-pink)
      );
      -webkit-background-clip: text;
      background-clip: text;
      opacity: 1;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 480px) {
    .hero-title {
      font-size: 2.5rem;
    }
  }
  
  .hero-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 150px;
    height: 4px;
    background: linear-gradient(90deg, var(--primary-blue), var(--accent-pink));
    animation: expandWidth 1.5s ease forwards;
    box-shadow: 0 2px 10px var(--glow-blue);
  }
  
  .hero-subtitle {
    font-size: 1.4rem;
    color: #e0e0e0;
    line-height: 1.6;
    max-width: 90%;
    margin-top: 2rem;
    opacity: 0;
    animation: fadeIn 1s ease forwards 0.5s;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    font-weight: 900;
    text-align: left;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    /* background: radial-gradient(
      circle at 70% 50%,
      var(--glow-blue) 0%,
      var(--glow-pink) 0%,
      transparent 25%
    ); */
  }
  
  /* Hero Lines Animation */
  .hero-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  
  .hero-line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    background: linear-gradient(
      to bottom,
      transparent,
      var(--glow-blue),
      transparent
    );
    animation: lineDrop 8s infinite;
  }
  
  .hero-line:nth-child(1) {
    left: 10%;
    animation-delay: 0s;
  }
  .hero-line:nth-child(2) {
    left: 25%;
    animation-delay: 2s;
  }
  .hero-line:nth-child(3) {
    left: 40%;
    animation-delay: 4s;
  }
  .hero-line:nth-child(4) {
    left: 55%;
    animation-delay: 6s;
  }
  .hero-line:nth-child(5) {
    left: 70%;
    animation-delay: 8s;
  }
  
  @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  @keyframes heroAni {
    0% {
        transform: translate(-300px, 0) scale(0);
        opacity: 0;
    }
    60% {
        transform: translate(20px, 0) scale(1);
        opacity: 0.5;
    }
    80% {
        transform: translate(20px, 0) scale(1);
        opacity: 0.75;
    }
    100% {
        transform: translate(0) scale(1);
        opacity: 1;
    }
  }

  /* Adaugă aceste stiluri la Hero.css existent */

.project-info {
  margin-top: 2rem;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.3);
  border-left: 4px solid var(--primary-blue);
  border-radius: 0 10px 10px 0;
  animation: fadeIn 1s ease forwards 0.5s;
  max-width: 800px;
}

.project-info p {
  color: #e0e0e0;
  margin: 1rem 0;
  font-size: 2rem;
  line-height: 1.6;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}

.project-info strong {
  color: var(--primary-blue);
  font-weight: 600;
}

.funding-info {
  margin-top: 1.5rem !important;
  padding-top: 1.5rem !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--primary-blue) !important;
  font-weight: 500 !important;
}

@media (max-width: 768px) {
  .project-info {
    padding: 1.5rem;
    margin-top: 1.5rem;
  }
  
  .project-info p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .project-info {
    padding: 1rem;
    margin-top: 1rem;
  }
  
  .project-info p {
    font-size: 0.85rem;
  }
}