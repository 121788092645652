:root {
    --primary-blue: #00a8ff;
    --secondary-blue: #0066cc;
    --accent-pink: #ff00ff;
    --deep-blue: #000033;
    --glow-blue: rgba(0, 168, 255, 0.2);
    --glow-pink: rgba(255, 0, 255, 0.2);
  }
  
  .navbar {
    background: linear-gradient(135deg, var(--deep-blue), #00042000);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    padding: 5px 0;
    transition: all 0.4s ease;
    box-shadow: 0 4px 30px rgba(0, 168, 255, 0.1);
    height: 140px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
        90deg,
        transparent,
        var(--primary-blue),
        var(--accent-pink),
        transparent
    );
    opacity: 0.3;
  }
  
  .container-nav {
    display: flex;
    justify-content: flex-start; /* Schimbat la flex-start pentru a permite controlul manual al spațierii */
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding: 0 30px;
    margin: 0 auto;
    position: relative; /* Adăugat pentru poziționare absolută a elementelor copil */
  }
  
  .navbar-brand {
    padding: 0;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .navbar-brand:hover {
    transform: scale(1.02);
  }
  
  .navbar-brand::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle, var(--glow-blue) 0%, transparent 70%);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .navbar-brand:hover::after {
    opacity: 1;
  }
  
  .navbar-brand img {
    height: 120px;
    width: auto;
    filter: drop-shadow(0 4px 12px var(--glow-blue));
    transition: all 0.4s ease;
  }
  
  .navbar.scrolled .navbar-brand img {
    height: 100px;
  }
  
  .navbar-collapse {
    position: absolute; /* Poziționare absolută pentru control precis */
    right: 0; /* Fixat în dreapta containerului */
    top: 50%; /* Centrat vertical */
    transform: translateY(-50%);
  }
  
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  
  .login-btn {
    position: relative;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    color: white;
    padding: 16px 40px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    text-decoration: none;
  }
  
  .login-btn::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    background: linear-gradient(135deg, var(--accent-pink), var(--primary-blue));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .login-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px var(--glow-blue);
    border-color: var(--primary-blue);
  }
  
  .login-btn:hover::before {
    opacity: 1;
  }
  
  .login-btn:active {
    transform: translateY(1px);
  }
  
  .login-btn span {
    position: relative;
    z-index: 2;
  }
  
  .navbar-toggler {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1000;
    position: relative;
  }
  
  .hamburger {
    width: 30px;
    height: 20px;
    position: relative;
    transition: .5s ease-in-out;
  }
  
  .hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--primary-blue);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  .hamburger span:nth-child(1) {
    top: 0px;
  }
  
  .hamburger span:nth-child(2) {
    top: 9px;
  }
  
  .hamburger span:nth-child(3) {
    top: 18px;
  }
  
  .navbar-toggler.active .hamburger span:nth-child(1) {
    top: 9px;
    transform: rotate(135deg);
  }
  
  .navbar-toggler.active .hamburger span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .navbar-toggler.active .hamburger span:nth-child(3) {
    top: 9px;
    transform: rotate(-135deg);
  }
  
  /* Media Queries */
  @media (max-width: 991.98px) {
    .navbar {
        height: 120px;
    }
  
    .container-nav {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        gap: 20px;
    }
  
    .navbar-brand {
        grid-column: 2;
        justify-self: center;
    }
  
    .navbar-toggler {
        display: block;
        grid-column: 3;
        justify-self: end;
    }
  
    .navbar-collapse {
        position: absolute;
        top: 120px;
        right: 0;
        transform: none; /* Reset transform pentru mobil */
        background: linear-gradient(135deg, var(--deep-blue), #000420ee);
        backdrop-filter: blur(12px);
        -webkit-backdrop-filter: blur(12px);
        width: 100%;
        padding: 20px;
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }
  
    .navbar-collapse.show {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
  
    .navbar-brand img {
        height: 100px;
    }
  
    .login-btn {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
  }
  
  @media (max-width: 575.98px) {
    .navbar {
        height: 100px;
    }
  
    .navbar-collapse {
        top: 100px;
    }
  
    .navbar-brand img {
        height: 80px;
    }
  
    .navbar.scrolled {
        height: 90px;
    }
  
    .navbar.scrolled .navbar-brand img {
        height: 70px;
    }
  
    .login-btn {
        padding: 10px 25px;
        font-size: 1rem;
    }
  
    .container-nav {
        padding: 0 15px;
        gap: 10px;
    }
  }