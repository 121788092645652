:root {
    --primary-blue: #00a8ff;
    --secondary-blue: #0066cc;
    --accent-pink: #ff00ff;
    --deep-blue: #000033;
    --glow-blue: rgba(0, 168, 255, 0.2);
    --glow-pink: rgba(255, 0, 255, 0.2);
  }
  
  .footer-section {
    background: linear-gradient(135deg, rgba(0, 0, 51, 0.95), rgba(0, 4, 32, 0.95));
    padding: 120px 0 0;
    position: relative;
    color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
  }
  
  .footer-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, 
      transparent 0%,
      var(--primary-blue) 25%,
      var(--accent-pink) 75%,
      transparent 100%
    );
    opacity: 0.3;
    filter: blur(1px);
  }
  
  .main-content {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
  }
  
  .footer-content {
    padding: 0 0 80px;
  }
  
  /* Contact Section */
  .contact-info {
    margin-bottom: 80px;
    text-align: center;
  }
  
  .contact-info h4 {
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 4rem;
    letter-spacing: 2px;
    color: white;
    position: relative;
    display: inline-block;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .contact-info h4::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: linear-gradient(90deg, var(--primary-blue), var(--accent-pink));
    box-shadow: 0 0 20px var(--glow-blue);
  }
  
  .contact-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-top: 60px;
  }
  
  .contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px 30px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 25px;
    transition: all 0.4s ease;
    border: 1px solid rgba(0, 168, 255, 0.1);
    position: relative;
    overflow: hidden;
  }
  
  .contact-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, var(--primary-blue), var(--accent-pink), transparent);
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .contact-item:hover {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 20px 40px rgba(0, 168, 255, 0.1);
    border-color: transparent;
  }
  
  .contact-item:hover::before {
    opacity: 1;
  }
  
  .contact-item i {
    font-size: 2.5rem;
    margin-bottom: 25px;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    transition: all 0.3s ease;
  }
  
  .contact-item:hover i {
    transform: scale(1.2);
  }
  
  .contact-item-content {
    width: 100%;
  }
  
  .contact-item-content h5 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 15px;
    color: white;
    letter-spacing: 0.5px;
  }
  
  .contact-item-content p {
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.8;
    font-size: 1.1rem;
    margin: 0;
  }
  
  /* Map Section */
  .map-container {
    height: 500px;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 168, 255, 0.15);
    border: 1px solid rgba(0, 168, 255, 0.1);
    margin: 80px 0;
    position: relative;
  }
  
  .map-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    padding: 1px;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  
  .map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    background: rgba(0, 0, 0, 0.3);
    padding: 40px 0;
    position: relative;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  
  .footer-bottom::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
      transparent,
      var(--primary-blue),
      var(--accent-pink),
      transparent
    );
    opacity: 0.2;
  }
  
  .bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  .social-links {
    display: flex;
    gap: 30px;
    justify-content: center;
  }
  
  .social-links a {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 50%;
    border: 1px solid rgba(0, 168, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.4rem;
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
  }
  
  .social-links a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .social-links a:hover {
    transform: translateY(-5px) scale(1.1);
    border-color: transparent;
  }
  
  .social-links a:hover::before {
    opacity: 0.1;
  }
  
  .social-links a:hover i {
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 1px;
  }
  
  .copyright a {
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .copyright a:hover {
    opacity: 0.8;
    text-shadow: 0 0 20px var(--glow-blue);
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .contact-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 991.98px) {
    .footer-section {
      padding: 80px 0 0;
    }
  
    .contact-info h4 {
      font-size: 2.5rem;
    }
  
    .map-container {
      height: 400px;
    }
  }
  
  @media (max-width: 767.98px) {
    .contact-grid {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  
    .main-content {
      padding: 0 20px;
    }
  
    .contact-info h4 {
      font-size: 2.2rem;
    }
  
    .contact-item {
      padding: 30px 20px;
    }
  
    .map-container {
      height: 300px;
      margin: 60px 0;
    }
  
    .social-links a {
      width: 50px;
      height: 50px;
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .contact-info h4 {
      font-size: 2rem;
    }
  
    .social-links {
      gap: 20px;
    }
  
    .social-links a {
      width: 45px;
      height: 45px;
      font-size: 1.1rem;
    }
  
    .copyright {
      font-size: 1rem;
      text-align: center;
    }
  }

  .eu-funding-info {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 2rem;
  }
  
  .info-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .info-link {
    color: var(--primary-blue);
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .info-link:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
  
  .funding-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .url-links {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  
  .web-link {
    color: var(--primary-blue);
    text-decoration: none;
    font-size: 0.95rem;
    transition: all 0.3s ease;
  }
  
  .web-link:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
  
  .aproape-button {
    background-color: #ffc107;
    color: #000;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
    font-weight: 500;
    font-size: 0.9rem;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
  
  .aproape-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 193, 7, 0.2);
  }
  
  @media (max-width: 768px) {
    .funding-links {
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      text-align: center;
    }
  
    .url-links {
      align-items: center;
    }
  
    .eu-funding-info {
      padding: 1.5rem 0;
    }
  }

  .aproape-button {
    background-color: #ffc107;
    color: #000;
    padding: 8px 30px;
    border-radius: 25px;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 20px;
    }
  
    .web-links {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  
    .aproape-button {
      margin-top: 10px;
    }
  }

  .footer-banner {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-banner {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .logo-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
  }

  @media (max-width: 768px) {
    .logo-row {
      flex-wrap: wrap;
      gap: 15px;
    }
    
    .banner-logo {
      height: 45px;
    }
    
    .info-button {
      font-size: 14px;
      padding: 10px 20px;
      text-align: center;
    }
  }

  .logo-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
  }

  @media (max-width: 768px) {
    .footer {
      padding: 20px;
    }
  
    .footer-content {
      flex-direction: column;
    }
  
    .footer-right {
      margin-left: 0;
      margin-top: 30px;
    }
  
    .web-links {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  
    .logo-row {
      flex-wrap: wrap;
      gap: 20px;
    }
    
    .banner-logo {
      height: 80px;
    }
  }

  .banner-logo {
    height: 80px;
    object-fit: contain;
  }

  .info-button {
    background-color: #ff6b35;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
  }
  
  .info-button:hover {
    background-color: #ff8255;
  }

  @media (max-width: 768px) {
    .logo-row {
      flex-wrap: wrap;
      gap: 15px;
    }
    
    .banner-logo {
      height: 45px;
    }
    
    .info-button {
      font-size: 14px;
      padding: 10px 20px;
      text-align: center;
    }
  }

  .info-button {
    background-color: #ff6b35;
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .info-button:hover {
    background-color: #ff8255;
  }