.floating-icons {
    position: fixed;
    right: 30px;
    bottom: 120px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .floating-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(45deg, var(--primary-blue), var(--accent-pink));
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .floating-icon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: translateX(-100%);
    transition: 0.4s ease;
  }
  
  .floating-icon:hover::before {
    transform: translateX(100%);
  }
  
  .floating-icon:hover {
    transform: scale(1.1) translateY(-5px);
    box-shadow: 0 10px 20px var(--glow-blue);
  }
  
  .floating-icon i {
    font-size: 1.5rem;
    transition: all 0.3s ease;
  }
  
  .floating-icon:hover i {
    transform: scale(1.2);
  }
  
  .chat-container {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 300px;
    height: 400px;
    z-index: 999;
  }
  
  @media (max-width: 768px) {
    .floating-icons {
        right: 20px;
        bottom: 100px;
        gap: 12px;
    }

    .floating-icon {
        width: 45px;
        height: 45px;
    }

    .chat-container {
        right: 20px;
        bottom: 20px;
        width: calc(100% - 40px);
        max-width: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .floating-icons {
        right: 15px;
        bottom: 90px;
        gap: 10px;
    }

    .floating-icon {
        width: 40px;
        height: 40px;
    }

    .floating-icon i {
        font-size: 1.2rem;
    }

    .chat-container {
        right: 15px;
        bottom: 15px;
        width: calc(100% - 30px);
    }
  }