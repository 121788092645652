:root {
    --primary-blue: #00a8ff;
    --secondary-blue: #0066cc;
    --accent-pink: #ff00ff;
    --deep-blue: #000033;
    --glow-blue: rgba(0, 168, 255, 0.2);
    --glow-pink: rgba(255, 0, 255, 0.2);
  }
  
  /* Main Section */
  .services-section {
    padding: 120px 0;
    background: linear-gradient(135deg, #000033bf, #000420);
    position: relative;
    overflow: hidden;
  }
  
  .container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Title Styling */
  .section-title {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 7rem;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
    padding-bottom: 2rem;
    width: 100%;
  }
  
  .section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 4px;
    background: linear-gradient(90deg, var(--primary-blue), var(--accent-pink));
    border-radius: 2px;
    box-shadow: 0 0 20px var(--glow-blue);
  }
  
  /* Services Grid */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 100px;
  }
  
  .service-item {
    background: rgba(255, 255, 255, 0.03);
    padding: 40px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 168, 255, 0.1);
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(0, 168, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .service-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--glow-blue), var(--glow-pink));
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 168, 255, 0.2);
    border-color: var(--primary-blue);
  }
  
  .service-item:hover::before {
    opacity: 0.1;
  }
  
  .service-icon {
    font-size: 3rem;
    margin-bottom: 2rem;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
    display: inline-block;
    transition: all 0.3s ease;
  }
  
  .service-item:hover .service-icon {
    transform: scale(1.1);
  }
  
  .service-item h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #ffffff;
  }
  
  .service-item p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 0;
  }
  
  /* Stats Section */
  .stats-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .stats-row {
    background: linear-gradient(135deg, 
      rgba(0, 168, 255, 0.1),
      rgba(255, 0, 255, 0.1)
    );
    padding: 60px 40px;
    border-radius: 20px;
    box-shadow: 0 15px 40px rgba(0, 168, 255, 0.1);
    border: 1px solid rgba(0, 168, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  
  .stats-counter {
    text-align: center;
    color: white;
    padding: 20px;
    position: relative;
  }
  
  .stats-counter:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: 1px;
    background: linear-gradient(to bottom, 
      transparent,
      rgba(0, 168, 255, 0.2),
      rgba(255, 0, 255, 0.2),
      transparent
    );
  }
  
  .counter-number {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .counter-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Shape Divider */
  .custom-shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }
  
  .custom-shape-divider svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 110px;
  }
  
  .custom-shape-divider .shape-fill {
    fill: var(--deep-blue);
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr);
      max-width: 800px;
    }
  
    .section-title {
      font-size: 3.5rem;
      margin-bottom: 5rem;
    }
  }
  
  @media (max-width: 991.98px) {
    .section-title {
      font-size: 3rem;
      margin-bottom: 4rem;
    }
  
    .stats-grid {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  
    .stats-counter::after {
      display: none;
    }
  }
  
  @media (max-width: 767.98px) {
    .services-grid {
      grid-template-columns: 1fr;
      max-width: 400px;
    }
  
    .section-title {
      font-size: 2.5rem;
      margin-bottom: 3rem;
    }
  
    .container {
      padding: 0 20px;
    }
  
    .service-icon {
      font-size: 2.5rem;
    }
  
    .service-item h4 {
      font-size: 1.3rem;
    }
  
    .counter-number {
      font-size: 3rem;
    }
  
    .counter-label {
      font-size: 1rem;
    }
  
    .stats-row {
      padding: 40px 20px;
    }
  }
  
  @media (max-width: 575.98px) {
    .container {
      padding: 0 15px;
    }
  
    .section-title {
      font-size: 2rem;
    }
  
    .service-item {
      padding: 30px 20px;
    }
  
    .stats-row {
      padding: 30px 15px;
    }
  }