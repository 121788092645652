/* ChatSystem.css */
:root {
    --primary-blue: #00a8ff;
    --secondary-blue: #0066cc;
    --accent-pink: #ff00ff;
    --deep-blue: #000033;
    --glow-blue: rgba(0, 168, 255, 0.2);
    --glow-pink: rgba(255, 0, 255, 0.2);
}

.chat-overlay {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1001;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  .chat-toggle-button {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    border: none;
    box-shadow: 0 4px 20px var(--glow-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    bottom: 23px;
    right: 0;
}
  
  .chat-toggle-button:hover {
    transform: scale(1.05);
    background: linear-gradient(135deg, var(--accent-pink), var(--primary-blue));
  }
  
  .chat-toggle-button svg {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
  }
  
  .chat-window {
    width: 24rem;
    height: 32rem;
    background: rgba(0, 0, 51, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    box-shadow: 0 8px 32px var(--glow-blue);
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 168, 255, 0.1);
    transform-origin: bottom right;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 1rem;
    z-index: 1002;
  }
  
  .chat-window.closed {
    transform: scale(0);
    opacity: 0;
  }
  
  .chat-window.open {
    transform: scale(1);
    opacity: 1;
  }
  
  .chat-header {
    padding: 1rem 1.5rem;
    background: linear-gradient(90deg, var(--primary-blue), var(--accent-pink));
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .chat-header-title {
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0;
  }
  
  .chat-header-actions {
    display: flex;
    gap: 0.75rem;
  }
  
  .chat-header-button {
    background: transparent;
    border: none;
    color: white;
    padding: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    transition: background-color 0.2s;
  }
  
  .chat-header-button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .message-bubble {
    max-width: 80%;
    padding: 0.875rem 1rem;
    border-radius: 1rem;
    line-height: 1.4;
    animation: messageAppear 0.3s ease-out;
  }
  
  .message-user {
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 0.25rem;
  }
  
  .message-ai {
    background: rgba(0, 168, 255, 0.1);
    color: white;
    align-self: flex-start;
    border-bottom-left-radius: 0.25rem;
  }
  
  .chat-input-container {
    padding: 1rem 1.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  
  .chat-input-wrapper {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
  
  .chat-input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(0, 168, 255, 0.1);
    border-radius: 0.5rem;
    background: rgba(0, 0, 51, 0.8);
    color: white;
    font-size: 0.95rem;
    transition: all 0.2s;
  }
  
  .chat-input:focus {
    outline: none;
    border-color: var(--primary-blue);
    background: rgba(0, 0, 51, 0.9);
  }
  
  .chat-send-button {
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    color: white;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .chat-send-button:hover:not(:disabled) {
    background: linear-gradient(135deg, var(--accent-pink), var(--primary-blue));
    transform: translateY(-1px);
    box-shadow: 0 4px 15px var(--glow-blue);
  }
  
  .chat-send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .typing-indicator {
    display: flex;
    gap: 0.3rem;
    padding: 0.75rem 1rem;
    background: rgba(0, 168, 255, 0.1);
    border-radius: 1rem;
    align-self: flex-start;
    width: fit-content;
  }

  /* Add these styles to your existing ChatSystem.css */

.admin-panel {
    padding: 1.5rem;
    background: rgba(0, 0, 51, 0.9);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(0, 168, 255, 0.1);
  }
  
  .admin-panel h4 {
    color: white;
    margin: 0 0 1rem 0;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .admin-panel textarea {
    width: 100%;
    height: 150px;
    padding: 1rem;
    background: rgba(0, 0, 51, 0.8);
    border: 1px solid rgba(0, 168, 255, 0.1);
    border-radius: 0.5rem;
    color: white;
    font-size: 0.9rem;
    resize: vertical;
    margin-bottom: 1rem;
  }
  
  .admin-panel textarea:focus {
    outline: none;
    border-color: var(--primary-blue);
  }
  
  .admin-panel button {
    background: linear-gradient(135deg, var(--primary-blue), var(--accent-pink));
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
  }
  
  .admin-panel button:hover {
    background: linear-gradient(135deg, var(--accent-pink), var(--primary-blue));
    transform: translateY(-1px);
  }
  
  .typing-dot {
    width: 0.5rem;
    height: 0.5rem;
    background: var(--primary-blue);
    border-radius: 50%;
    animation: typingAnimation 1.4s infinite ease-in-out;
  }
  
  .typing-dot:nth-child(1) { animation-delay: 0s; }
  .typing-dot:nth-child(2) { animation-delay: 0.2s; }
  .typing-dot:nth-child(3) { animation-delay: 0.4s; }
  
  @keyframes messageAppear {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes typingAnimation {
    0%, 60%, 100% { transform: translateY(0); }
    30% { transform: translateY(-4px); }
  }
  
  @media (max-width: 768px) {
    .chat-window {
        width: 90vw;
        height: 70vh;
        position: fixed;
        bottom: 5rem;
        left: 50%;
        transform: translate(-50%, 0) scale(1);
        margin: 0;
        border-radius: 1rem;
        max-width: 400px;
    }

    .chat-window.closed {
        transform: translate(-50%, 0) scale(0);
        opacity: 0;
    }

    .chat-window.open {
        transform: translate(-50%, 0) scale(1);
        opacity: 1;
    }

    .chat-overlay {
        bottom: 1rem;
        right: 1rem;
        left: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .chat-toggle-button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 3rem;
        height: 3rem;
    }
  }

  /* Adjust medium-sized devices */
  @media (min-width: 769px) and (max-width: 1024px) {
    .chat-window {
        width: 24rem;
        height: 32rem;
        bottom: 5rem;
        right: 2rem;
        position: fixed;
    }
  }

  /* Handle smaller height screens */
  @media (max-height: 600px) {
    .chat-window {
        height: 60vh;
        min-height: 300px;
    }
  }

  /* Landscape mode adjustments */
  @media (max-width: 768px) and (orientation: landscape) {
    .chat-window {
        height: 80vh;
        width: 60vw;
        max-width: 500px;
        bottom: 5rem;
        transform: translate(-50%, 0) scale(1);
    }

    .chat-window.closed {
        transform: translate(-50%, 0) scale(0);
    }

    .chat-window.open {
        transform: translate(-50%, 0) scale(1);
    }
  }